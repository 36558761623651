export default {
  /* eslint-disable no-unused-vars */
  install(Vue, { store, uuid }) {
   
    Vue.prototype.$showSnack = (text, color="secondary", timeout = 3000) => {
      console.log("showMessage", uuid);
      
        store.dispatch("showMessage", {
          id: uuid.v1(),
          show: true,
          timeout: timeout,
          text: text,
          color:color
        })
    };
  }
  /* eslint-enable no-unused-vars */
};