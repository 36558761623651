import Vue from 'vue'
import 'babel-polyfill';
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import App from './App.vue'
import router from './router';
import store from "./store";
import { auth } from "./firebase";
import vuetify from './plugins/vuetify';
import "@/plugins/Dayjs";
import snacks from "@/plugins/Snacks";
Vue.config.productionTip = false

import UUID from "vue-uuid";
Vue.use(UUID);
Vue.filter('postDate', function(value) {
  if (value) {
      return Vue.prototype.$date(value).format("ddd (MM/DD) hh:mm a")
        
    }
});
Vue.use(snacks, {
  store: store,
  uuid: Vue.prototype.$uuid
});
let app;
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }
  console.log("auth change on main", user);
  //TODO: instead do the call for identity token
  /*if (user) {
    console.log("auth state fetching details");
    store.dispatch("fetchUserProfile", user);
  }*/
});
