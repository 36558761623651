import { firebase } from "@firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyB9V_fnCNcgzgsxVgxeQNsqcpnsqQp910w",
  authDomain: "wtduck-a3e87.firebaseapp.com",
  projectId: "wtduck-a3e87",
  storageBucket: "wtduck-a3e87.appspot.com",
  messagingSenderId: "48679403531",
  appId: "1:48679403531:web:43978719559ace275ec70e"
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();
const SERVER_TIME = firebase.firestore.FieldValue.serverTimestamp();
const ARRAY_UNION = firebase.firestore.FieldValue.arrayUnion;
const ARRAY_REMOVE = firebase.firestore.FieldValue.arrayRemove;
const GOOGLE_PROVIDER = new firebase.auth.GoogleAuthProvider();
const DOC_ID = firebase.firestore.FieldPath.documentId();
const STATE_CHANGED = firebase.storage.TaskEvent.STATE_CHANGED;
const FROM_DATE = firebase.firestore.Timestamp.fromDate;

db.enablePersistence({ experimentalTabSynchronization: true })
  .then(() => {
    console.log("DB Persistence enabled");
  })
  .catch(function(err) {
    console.log("fb error", err);
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
    console.log("DB Persistence error:", err);
  });

// collection references
const usersCollection = db.collection("users");
const profileCollection = db.collection("profiles");

// export utils/refs
export {
  db,
  auth,
  storage,
  functions,
  usersCollection,
  profileCollection,
  SERVER_TIME,
  ARRAY_UNION,
  ARRAY_REMOVE,
  GOOGLE_PROVIDER,
  DOC_ID,
  STATE_CHANGED,
  FROM_DATE
};
