<template>
  <!--	<v-avatar class="mr-10" color="grey darken-1" size="32"></v-avatar>-->
  <v-list grow nav>
    <v-list-item v-if="authenticated && userProfile.photoURL">
      <v-list-item-avatar>
        <v-img :src="userProfile.photoURL"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    <v-list-item link to="/settings" v-if="authenticated">
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ userProfile.displayName }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ userProfile.email }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-icon>mdi-account-edit</v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-list-item v-else>
      <v-btn color="primary" @click="login">Login</v-btn>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item color="blue" text to="/">Home</v-list-item>
    <v-list-item v-if="authenticated" color="blue" text to="/api">API</v-list-item>
    <v-spacer></v-spacer>
    <!-- profile/signin-->

    <v-list-item>
      <v-list-item-title
        link
        type="button"
        v-if="authenticated"
        @click="logout"
      >
        Logout
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    async login() {
      await this.$store.dispatch("login", this);
    },
    async logout() {
      await this.$store.dispatch("logout", this);
    },
  },
  computed: {
    ...mapState(["userProfile"]),
    authenticated() {
      return this.userProfile.uid;
    },
    hide() {
      return !!this.$route.query.embedded === true;
    },
  },
  watch: {
    userProfile(n, o) {
      console.log("nav user updated", n, o);
    },
  },
};
</script>
