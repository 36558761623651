/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";
import * as fb from "../firebase";
//import { GOOGLE_PROVIDER } from "@/firebase";
import { firebase } from "@firebase/app";
Vue.use(Vuex);

const initialState = () => {
	return {
		userProfile: {
			firstname: "",
			lastname: "",
			email: "",
			displayName: "",
			theme: "",
			hasRole(role) {
				return false;
			},
			isOnTeam(team) {
				return false;
			}
		},
		app: {
			persistence: {
				status: false,
				message: "",
			},
		},
		teams: [],
		snacks: []
	};
};
export default new Vuex.Store({
	state: initialState(),
	mutations: {
		//user profile
		setUserProfile(state, payload) {
			state.userProfile = Object.assign(initialState().userProfile, payload);
		},
		setPersistence(state, payload) {
			state.app.persistence = payload;
		},
		setTeamData(state, payload) {
			let exists = false;
			state.teams.forEach((team) => {
				if (!exists && team.name === payload.name) {
					team.members = payload.members;
					exists = true;
				}
			});
			if (!exists) {
				state.teams.push({
					name: payload.name,
					members: payload.members,
				});
			}
		},
		addSnack(state, payload) {
			if (state.snacks && Array.isArray(state.snacks)) {
				state.snacks.push(payload);
			}
			else {
				state.snacks = [payload];
			}
			state.snacks = state.snacks.filter(snack => snack.show);
		}
	},
	getters: {
		user: (state) => state.userProfile,
		getTeamByName: (state) => (teamName) => {
			return state.teams.find((team) => team.name === teamName) || [];
		},
		getSnacks: (state) => state.snacks
	},
	actions: {
		async showMessage({ commit }, message) {
			commit("addSnack", message);
		},
		async fetchTeamInfo({ commit }, team) {
			const members = this.getters.getTeamByName(team);
			if (members.length === 0) {
				console.log("DB call for ", team);
				let getTeamList = fb.functions.httpsCallable("teamList");
				return await getTeamList({ team: team })
					.then(async (result) => {
						console.log("store team result", result);
						commit("setTeamData", {
							name: team,
							members: result.data,
						});
						return { members: result.data };
					})
					.catch((error) => {
						// Getting the Error details.
						var code = error.code;
						var message = error.message;
						var details = error.details;
						console.log(code, message, details);
						// ...
					});
			} else {
				return members;
			}
		},
		async authAction({ commit }) {
			await fb.auth.onAuthStateChanged((user) => {
				if (user) {
					console.log("authAction()", user);
					this.dispatch("fetchUserProfile", user).then(() => {
						return true;
					});
				} else {
					commit("setUserProfile", initialState().userProfile);
				}
			});
		},

		async fetchUserProfile({ commit }, user) {
			let u = await fb.usersCollection.doc(user.uid).get();
			if (u.exists) {
				const ud = u.data();
				console.log("fetchUser Data", user);
				user.firstname = ud.firstname;
				user.lastname = ud.lastname;
				user.permissions = ud.permissions;
				user.bio = ud.bio;
				user.ucid = ud.ucid;
				Vue.set(user, "theme", ud.theme);
				if (!!user.token === false) {
					user.token = ud.token;
				}
				if (!!user.idToken === false) {
					user.idToken = ud.idToken;
				}
				user.hasRole = (role) => {
					if (!user.permissions) {
						return false;
					}
					if (user.permissions.includes("admin") || user.permissions.includes(role)) {
						return true;
					}
					return false;
				};
				user.isOnTeam = (team) => {
					if (user.teams) {
						return team in user.teams;
					}
					return false;
				}
				commit("setUserProfile", user);
			}
		},
		async logout({ commit }) {
			await fb.auth.signOut().then(() => {
				commit("setUserProfile", initialState().userProfile);
			});

			// clear user data from state

			// redirect to login view
		},
		async login({ dispatch }) {
			const self = this;

			let provider = new firebase.auth.GoogleAuthProvider();
			//provider.addScope("https://www.googleapis.com/auth/documents");
			provider.addScope("profile");
			provider.addScope("email");
			provider.setCustomParameters({
				hd: "njit.edu",
			});
			return fb.auth
				.signInWithPopup(provider)
				.then(async function(result) {
					console.log("login result", result);
					let user = result.user;
					if (user.email.indexOf("njit.edu") > -1) {
						const ucid = user.email.split("@")[0].toLowerCase();
						console.log(ucid);
						let updateUserProfile = fb.functions.httpsCallable("updateUserProfile");

						return await updateUserProfile({
							id_token: result.credential.idToken,
							token: result.credential.accessToken,
							photoURL: user.photoURL,
						})
							.then((result) => {
								console.log("function result", result);
								let merged = Object.assign(JSON.parse(JSON.stringify(user)), result.data);
								console.log("merged user", merged);
								return self.dispatch("fetchUserProfile", merged).then(() => {
									console.log("After fetch user profile");
								});
							})
							.catch((error) => {
								console.log("Function error", error);
								var code = error.code;
								var message = error.message;
								var details = error.details;
								console.log(code, message, details);
							});
					} else {
						throw new Error("Must use an NJIT email address");
					}
				})
				.catch(function(error) {
					const errorCode = error.code;
					const errorMessage = error.message;
					const email = error.email;
					const credential = error.credential;
					console.log(errorCode, errorMessage, email, credential);
				});
		},
	},
	modules: {},
});
