<template>
	<v-sheet v-if="getSnacks.length > 0">
		<v-snackbar :style="{'margin-bottom':calcMargin(index)}"
			v-for="(snack,index) in visibleSnacks"
			:key="snack.id"
			v-model="snack.show"
			:timeout="snack.timeout"
            :color="snack.color"
		>
			{{ snack.text }}

			<template v-slot:action="{ attrs }">
				<v-btn
					
					text
					v-bind="attrs"
					@click="snack.show = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    methods:{
        calcMargin(i) {
            return (i*60) + 'px'
        },
    },
	computed: {
		...mapGetters(["getSnacks"]),
        visibleSnacks(){
            return this.getSnacks.filter(snack=>snack.show);
        }
	},
};
</script>