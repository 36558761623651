import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "../firebase";
Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "Index",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Index.vue"),
    meta: {
      requiresAuth: false
    }
    },
 {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Settings.vue"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
 {
    path: "/profile/:user/:edit?",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    meta: {
      requiresAuth: false
    },
    props: true
    },
 {
    path: "/api",
    name: "API",
    component: () =>
      import(/* webpackChunkName: "API" */ "../views/API.vue"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/NotFound.vue")
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// eslint-disable-line no-unused-vars
router.beforeEach((to, from, next) => {
  //console.log(from);
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const roles = to.meta.roles;
  //console.log("roles", roles);
  if (roles && roles.length > 0 && auth.currentUser) {
    auth.currentUser.getIdTokenResult().then(tokenResult => {
      //console.log(tokenResult.claims);
      for (const index in roles) {
        const role = roles[index];
        console.log("router() has_role", role in tokenResult.claims);
        if ("admin" in tokenResult.claims || role in tokenResult.claims) {
          next();
          return;
        }
      }
      next("/403");
    });
  } else {
    if (requiresAuth && !auth.currentUser) {
      next("/login");
    } else {
      next();
    }
  }
});

export default router;
