<template>
  <v-app>
    <v-navigation-drawer app temporary tile v-model="drawer">
      <v-btn v-if="drawer" @click.stop="drawer = !drawer">
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <Nav app></Nav>
    </v-navigation-drawer>

    <v-app-bar app color="secondary">
      <v-btn v-if="!drawer" @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-spacer></v-spacer>

      <v-img
        contain
        :eager="true"
        :aspect-ratio="16 / 9"
        height="50"
        lazy-src="/images/logo.png"
        src="/images/logo.png"
      ></v-img>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer padless>
      <snacks></snacks>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>What The Duck</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Nav from "@/components/Nav";
import Snacks from './components/Snacks.vue';
export default {
  name: "App",
  components: {
    Nav,
    Snacks,
  },
  mounted() {
    this.authAction;
    console.log("mounted theme", this.theme);
  },
  data() {
    return {
      drawer: false,
      versionListener: false,
      currentVersion: "",
      needsReload: false,
      attrs: {
        class: "mb-6",
        boilerplate: false,
        light: true,
        elevation: 2,
      },
    };
  },
  methods: {
    async login() {
      await this.$store.dispatch("login", this);
    },
    async logout() {
      await this.$store.dispatch("logout", this);
    },
    reload() {
      window.location.reload();
    },
    unsub() {
      if (this.versionListener) {
        this.versionListener();
        console.log("unsubscribed from versions");
      }
    },
    /*watchVersions() {
      this.unsub();
      this.versionListener = versionCollection
        .orderBy("deployed", "desc")
        .limit(1)
        .onSnapshot(
          querySnapshot => {
            if (!querySnapshot.empty) {
              let current = window.localStorage.getItem("learn.version");
              console.log("stored version", current);
              let data = querySnapshot.docs[0].data();
              data.id = querySnapshot.docs[0].id;
              if (current && current != data.version) {
                //window.localStorage.setItem("learn.version", data.version);
                this.needsReload = true;
              }
              window.localStorage.setItem("learn.version", data.version);
              this.currentVersion =
                data.version +
                " - " +
                this.$date(this.$helpers.getDate(data.deployed)).format(
                  "ddd (MM/DD) hh:mm a"
                );
            }
          },
          err => {
            console.log("Error checking version", err);
          }
        );
    }*/
  },
  beforeDestroy() {
    this.unsub();
  },
  computed: {
    ...mapActions(["authAction"]),
    ...mapState(["userProfile"]),
    isLoggedIn() {
      return this.userProfile && this.userProfile.uid;
    },
    isVerified() {
      return this.userProfile && this.userProfile.emailVerified;
    },
    theme() {
      if (this.userProfile && !!this.userProfile.theme) {
        let t = this.userProfile.theme || "light";
        window.localStorage.theme = t;
        return t;
      } else {
        return window.localStorage.theme || "light";
      }
    },
  },
  watch: {
    theme: {
      immediate: true,
      handler(n) {
        console.log("theme change", n);
        this.$vuetify.theme.dark = n === "dark";
        document.body.className = this.$vuetify.theme.dark ? "black" : "white";
      },
    },
  },
};
</script>
<style>
html {
  overflow-y: auto;
  /*line-height: 1.4;*/
}
.black {
  background-color: black;
}
.white {
  background-color: white;
}
</style>
